.Page {
  max-width: 700px;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding: 72px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 90%;
  }

  .larger {
    font-size: 1.6em;
  }

  h1 {
    text-align: center;
  }

  p, h2 {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  ol {
    width: 70%;

    li {
      padding: 3px;
    }
  }

  .pricing {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .price {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      padding: 6px;
      border-radius: 6px;
      box-shadow: 0 30px 30px 10px rgba(darken($green, 10), 0.1);

      .amount {
        &:before {
          content: '€';
        }

        font-family: sans-serif;
        font-size: 1.2rem;
      }
    }
  }

  .next {
    width: 100%;
    background-color: $green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px;
    margin-top: 24px;
    text-decoration: none;
    cursor: pointer;

    &.bordered {
      border: 2px solid $green;
    }

    i {
      color: white;
    }

    font-size: 2rem;
    border-radius: 6px;
    outline: none;
    border: none;

    &:hover {
      background-color: darken($green, 10);
    }
  }

  form {
    margin-top: 12px;
    width: 100%;

    .field {
      display: flex;
      flex-direction: column;
      width: 100%;

      input {
        font-size: 1.1rem;
        padding: 12px;
        margin: 6px 0 12px 0;
        outline: none;
        border: 2px solid $green;
        border-radius: 6px;
      }
    }

    .fields {
      display: flex;
      gap: 12px;
      .field {
        width: 50%;
      }
    }
  }

  .error {
    background-color: rgba(white, 0.4);
    border-radius: 6px;
    border: 2px solid rgba(red, 0.2);
    font-family: sans-serif;
    padding: 12px;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .emp {
    font-size: 1.1em;
  }
}