@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');
@import "@fortawesome/fontawesome-free/css/all.min.css";

$green: rgb(83, 105, 66);
@import "pages/PlantPage";

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.3);
}

html {
  background-image: url("../public/flower.jpeg");
  background-attachment: fixed;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Forum', cursive;
  color: $green;
}

p {
  font-size: 1.1rem;
}